<template>
    <Init/>

    <Head>
        <title>Inloggen</title>
    </Head>

    <div class="d-flex min-vw-100 min-vh-100 align-items-center">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="card border-0 bg-body-secondary p-0" style="max-width: 900px; min-height: 300px">
                    <div class="card-body p-0 d-flex flex-row">
                        <div class="d-lg-block d-none col-lg-6">
                            <img :src="selectedImage" alt="test" class="rounded-start-2" style="max-width: 450px">
                        </div>

                        <div class="col-lg-6 col d-flex flex-column justify-content-center align-content-center p-3">
                            <div class="position-absolute top-0 end-0 p-1">
                                <ThemeButton/>
                            </div>

                            <div class="d-flex flex-row justify-content-center" style="min-height: 100px">
                                <img :src="prismaLogo" alt="Prisma logo" style="max-height: 100px"
                                     :class="{'bg-light rounded-3': themeStore.theme === 'dark'}">
                            </div>

                            <template v-if="!showForgotPasswordForm">
                                <!-- Login form -->
                                <form
                                    v-if="!props.is2fa"
                                    class="d-flex flex-column flex-grow-1"
                                    @submit.prevent="submit"
                                >
                                    <div class="input-group mt-2">
                                        <div class="input-group-text">
                                            <font-awesome-icon icon="fa-solid fa-user" fixed-width/>
                                        </div>
                                        <input v-model="loginForm.login" type="text" name="Gebruikersnaam"
                                               class="form-control" placeholder="Gebruikersnaam" required autofocus>
                                    </div>

                                    <div class="input-group mt-2">
                                        <div class="input-group-text">
                                            <font-awesome-icon icon="fa-solid fa-key" fixed-width/>
                                        </div>
                                        <input v-model="loginForm.password" type="password" name="password"
                                               class="form-control" placeholder="Wachtwoord" required>
                                    </div>

                                    <div class="mt-auto">
                                        <div class="d-grid mt-2">
                                            <button type="submit" class="btn btn-primary" name="submit">
                                                Inloggen
                                            </button>
                                        </div>

                                        <div class="mt-1 d-flex flex-row small">
                                            <div v-if="loginForm.errors.login" class="text-danger">
                                                {{ loginForm.errors.login }}
                                            </div>

                                            <div class="text-end flex-grow-1">
                                                <span style="cursor: pointer"
                                                      class="text-muted"
                                                      @click="showForgotPasswordForm = true">
                                                    Wachtwoord vergeten?
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                                <!-- 2fa form -->
                                <form
                                    v-else
                                    class="d-flex flex-column flex-grow-1"
                                    @submit.prevent="submit2fa"
                                >
                                    <div class="input-group mt-2">
                                        <div class="input-group-text">
                                            <font-awesome-icon icon="fa-solid fa-key" fixed-width/>
                                        </div>
                                        <input
                                            v-model="twoFactorForm.code"
                                            id="2faCode"
                                            type="text"
                                            name="code"
                                            class="form-control"
                                            placeholder="Two Factor code"
                                        >
                                    </div>

                                    <div class="mt-auto">
                                        <div class="d-grid mt-2">
                                            <button type="submit" class="btn btn-primary" name="submit">
                                                Inloggen
                                            </button>
                                        </div>

                                        <div class="text-danger small mt-1">
                                            {{ $page.props.errors.code }}&nbsp;
                                        </div>
                                    </div>
                                </form>
                            </template>

                            <template v-else>
                                <!-- Forgot Password form -->
                                <form
                                    class="d-flex flex-column flex-grow-1"
                                    @submit.prevent="submitForgotPassword"
                                >
                                    <div class="mt-1 text-center">
                                        Wachtwoord vergeten? Vul je e-mailadres in.<br>
                                        Je ontvangt dan een e-mail met instructies.
                                    </div>

                                    <div class="input-group mt-1">
                                        <div class="input-group-text">
                                            <font-awesome-icon icon="fa-solid fa-at" fixed-width/>
                                        </div>
                                        <input v-model="forgotPasswordForm.email" type="email" name="Email"
                                               class="form-control" placeholder="Email" required>
                                    </div>

                                    <div class="mt-auto">
                                        <div class="d-grid mt-2">
                                            <button type="submit" class="btn btn-primary" name="submit">
                                                Verzenden
                                            </button>
                                        </div>

                                        <div class="mt-1 d-flex flex-row small">
                                            <div class="text-danger">
                                                {{ $page.props.errors.email }}&nbsp;
                                            </div>

                                            <span
                                                style="cursor: pointer"
                                                class="text-muted text-end flex-grow-1"
                                                @click="showForgotPasswordForm = false"
                                            >
                                                Terug naar Inloggen
                                            </span>
                                        </div>
                                    </div>
                                </form>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {nextTick, ref, watch} from "vue";
import {Head, useForm} from "@inertiajs/vue3";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {useThemeStore} from "@/Shared/Stores/ThemeStore";
import {newToast} from "@/Shared/ToastService";
import Init from "@/Shared/Init.vue";
import prismaLogo from "#/logo-Prisma-Beveiliging.svg";
import ThemeButton from "@/Shared/ThemeButton.vue";

const props = defineProps({
    is2fa: {type: Boolean, default: false}
})
const themeStore = useThemeStore()

// Images range from 1 to 50
const imageNumber = (Math.floor(Math.random() * 50) + 1);
const selectedImage = new URL(`/resources/assets/login/${imageNumber}.jpg`, import.meta.url).href;
const showForgotPasswordForm = ref(false)

const loginForm = useForm({
    login: null,
    password: null,
});

watch(
    () => props.is2fa,
    (is2fa) => {
        if (is2fa) {
            nextTick().then(() => {
                document.getElementById("2faCode").focus()
            })
        }
    }
)

const submit = () => {
    loginForm.post(
        route('login.store'),
        {
            onError: () => loginForm.reset('password'),
        }
    );
}

const twoFactorForm = useForm({
    code: null,
});

const submit2fa = () => {
    twoFactorForm.post(
        route('login.store'),
        {
            onError: () => twoFactorForm.reset('code'),
        }
    );
}

const forgotPasswordForm = useForm({
    email: null,
})

const submitForgotPassword = () => {
    forgotPasswordForm.post(
        route('password.forgot'),
        {
            onSuccess: () => {
                newToast('Email verzonden')
            },
            onError: (errors) => {
                console.log(errors)
            }
        }
    )
}
</script>

